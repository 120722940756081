import { postAddToCart, handleMsgTip } from 'public/src/pages/goods_detail_v2/utils/add-bag.js'
import { SMessage } from '@shein-aidc/sui-message'


const _showCartPop = (result, notPop) => {
  // 转换bff数据给购物车组件
  if (result?.info?.quantity) {
    result.info.cartSumQuantity = result.info.quantity
  }

  window.cart_module && window.cart_module.recart(result, notPop)  // 第二个参数为true, 则不会唤起miniCart
}

export default {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  getters: {
    buy_box_recommend_info(state, getters, rootState) {
      return rootState.buyBoxRecInfoBff || {}
    },
    show_buy_box(state, getters) {
      return getters.buy_box_recommend_info?.multiStoreProducts?.length > 0
    },
    buybox_recommend_info_params(state, getters, rootState, rootGetters) { // 融合后删除
      let { detail } = rootState.productIntroData || {}
      const mallCode = rootGetters['mallCode']
      const { goods_id, cat_id, goods_sn } = detail
      return {
        pageQueryParam: {
          limit: 10,
          page: 1,
        },
        goodsId: goods_id,
        cateId: cat_id,
        goodsSn: goods_sn,
        mallCode,
        appPage: 'product_detail_outside',
      }
    },
  },
  actions: {
    async one_sku_add_to_bag({ rootState }, productItem = {}) {
      const { goods_id, mall_code, sku_code } = productItem || {}
      if (typeof goods_id === 'undefined' || typeof mall_code === 'undefined' || typeof sku_code === 'undefined') throw 'add invalid params'

      const data = {
        goods_id,
        mall_code,
        sku_code,
        quantity: 1
      }

      const language = rootState.language

      const res = await postAddToCart(data)

      const _isAddSuccess = res?.code == 0
      if (_isAddSuccess) {
        _showCartPop(res)
        return res
      }

      const msgTips = handleMsgTip(res, { language })
      // 错误才做提示
      if (!_isAddSuccess && msgTips.msgContent) {
        SMessage({
          message: msgTips.msgContent,
          type: 'error',
          offset: 180,
        })
      }

      return res
    },
  }

}
