import { daEventCenter } from 'public/src/services/eventCenter/index'
import { getQueryString, transformImg, getLocalStorage } from '@shein/common-function'
import itemSer from 'public/src/services/goodsItemInfo/interfaceSer'
import { abtservice } from 'public/src/services/abt'
import schttp from 'public/src/services/schttp'
import { formatBrandSeriesDetail } from '../utils/common'
import { promotionCenter } from 'public/src/pages/common/promotion'
import { cloneDeep } from '@shein/common-function'
import { abtUserAnalysis, getUserAbtData } from '@shein-aidc/basis-abt-router'
import { blurStoreItemsNum } from 'public/src/pages/goods_detail_v2/utils/blurStoreItemsNum.js'
import { SToast } from '@shein-aidc/sui-toast'
import { isLogin, closestRatio } from 'public/src/pages/common/utils/index.js'
import { userModule } from 'public/src/pages/common/user_module.js'
import { buyNow } from 'public/src/pages/goods_detail_v2/utils/buyNow.js'
import { getDetailRecPreTreat } from 'public/src/pages/goods_detail_v3/utils/productDetail.js'
import { triggerSubmitBuyNow } from 'public/src/pages/goods_detail_v2/utils/buyNow.js'
import { getCalcBundlePrice } from 'public/src/pages/goods_detail_v2/components/RecommendRelatives/CommonCart/utils.js'
import { getDetailRecommendCardV3ReqAbtInfoCCCParams } from 'public/src/pages/goods_detail_v2/utils/productDetail.js'
import getEstimatedInfo from 'public/src/pages/goods_detail_v2/utils/getEstimatedInfo'
import { getDetailRecommendAtomFields } from 'public/src/pages/goods_detail_v2/utils/productDetail.js'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import { setDetailCacheCookie } from 'public/src/pages/common/utils/index.js'
import { getRankingLink } from 'public/src/pages/goods_detail_v2/utils/productDetail.js'
import { SILogger } from 'public/src/pages/common/monitor/index.js'
import { getBuyboxRecommendInfo } from 'public/src/pages/goods_detail_v2/components/BuyBoxBff/utils.js'
import prefetchBTF from 'public/src/pages/detail/main/services/prefetchBTF.js'
// import { getCookie } from '@shein/common-function'

daEventCenter.addSubscriber({ modulecode: '1-8-4' })
daEventCenter.addSubscriber({ modulecode: '1-8-6' })
daEventCenter.addSubscriber({ modulecode: '2-21' })

function validStoreCode(store_code) {
  const code = parseInt(+store_code)
  return code && code !== 1
}

const isValidCache = (cache) => {

  const user = gbCommonInfo.user
  const SiteUID = gbCommonInfo.SiteUID

  // 判断是否同一个用户
  if (cache.memberId != user?.member_id) return false
  // 判断是否退出登录过
  if (cache.loginTimeLast != user?.login_time_last) return false
  // 判断是否同一个站点
  if (cache.siteUid != SiteUID) return false
  return true
}

// 处理主图蒙层
const handledMaskLayer = ({ maskLayerSrc = '', maskLayerHeight = null, maskLayerWidth = null }) => {
  if (!!maskLayerSrc && !!maskLayerHeight && !!maskLayerWidth) {
    return {
      src: maskLayerSrc,
      imgRatio: closestRatio(maskLayerWidth / maskLayerHeight)
    }
  }
}
const preFetchQueues = {}

const logForSsrAdvertisementAbPrice = ({
  goods_id,
  mall_code,
  closeEstimatedAndAbPrice,
  realTimeGetPrice
}) => {
  if (!closeEstimatedAndAbPrice) return 
  let ssrData = window.gbRawData
  // let isAb = !!ssrData.productIntroData?.detail?.mall?.[mall_code]?.promotionInfo?.find?.(item => item?.typeId == 32)
  let { salePrice: ssrSalePrice, retailPrice: ssrRetailPrice } = ssrData.productIntroData?.detail?.mall?.[mall_code]
  let { salePrice: realSalePrice, retailPrice: realRetailPrice } = realTimeGetPrice
  let priceIsDiff = ssrSalePrice.amount != realSalePrice.amount || ssrRetailPrice.amount != realRetailPrice.amount
  let isProd = gbCommonInfo.NODE_SERVER_ENV === 'production'
  if (isProd && priceIsDiff) {
    SILogger.logInfo({
      tag: 'GOODS_DETAIL_PC_V3_ADVERTISEMENT_AB_PRICE',
      message: 'GOODS_DETAIL_PC_V3_ADVERTISEMENT_AB_PRICE',
      params: {
        goods_id,
        mall_code,
        ssrSalePrice,
        ssrRetailPrice,
        realSalePrice,
        realRetailPrice,
        url: window.location.href,
        fromCache: gbRawData?.fromCache,
        user: UserInfoManager.get({ key: 'oest' })
      }
    })
  }
}

const actions = {
  // 获取当前商品数据
  getProductIntroData({ state, dispatch, commit }, goodsId) {
    const { closeEstimatedAndAbPrice } = state.productIntroData // 屏蔽到手价
    state.loading = false
    commit('assignState', { realTimeBffReady: false, updateStoreReady: false, realTimeDataReady: false })
    schttp({
      url: '/api/productInfo/quickView/get',
      params: {
        goods_id: goodsId,
        mallCode: state.productIntroData.mallCode,
        isFromDetail: 1,
        specialSceneType: closeEstimatedAndAbPrice ? 1 : 0,
        isGoodsDetail: true
      }
    }).then((res) => {
      if (res && Number(res.code) === 0 && res.info) {
        commit('setProductIntroData', res.info.goods)
        // dispatch('getRealtimePrice') // 已迁移 realTime
        // dispatch('getCCCDescConfig') // 已迁移 realTime
        // dispatch('getProductBadges') // 已迁移 realTime
        dispatch('asyncHotModles')
        dispatch('updateRealBffInfo')
        dispatch('getAllSignDetail')
        state.loading = true
      }
    })
  },
  async fetchCouponList({ state, getters, commit }) {
    if (
      !state.productIntroData.mallCode ||
      !state.productIntroData.detail.store_code ||
      state.productIntroData.detail.store_code == 1 ||
      (getters.isEstimatedMatch || getters.getEstimatedInfo?.isAb)
    )
      return
    const skc = state.productIntroData.detail.goods_sn
    const products = []
    skc && products.push({ skc })
    await new Promise((resolve) => {
      schttp({
        url: `/api/coupon/getCouponListStore/get`,
        method: 'POST',
        data: {
          mall_code: state.productIntroData.mallCode,
          store_code: state.productIntroData.detail.store_code,
          products
        },
      }).then((data) => {
        const mall = { ...(data.info?.mall_info || {}) }
        const store = { ...(data.info?.mall_info?.store_info || {}) }
        const coupons = data.info?.mall_info?.store_info?.coupon_info || []
        let couponList = coupons
          .filter(_ => _?.coupon_status != '3') // 过滤已领取完的优惠券
          .map(item => {
            return Object.assign(item, { mall, store })
          })
        commit('assignState', { couponList })
        resolve()
      })
    })
  },
  // 获取实时价格
  async getRealtimePrice({ state, getters, dispatch }) {

    const goodsId = state.currentGoodsId
    const { closeEstimatedAndAbPrice } = state.productIntroData // 屏蔽到手价
    const [res, attrRes] = await Promise.all([
      itemSer.getAtomicInfo({
        goods: [{ goods_id: goodsId, mall_code: getters.mallCode }],
        fields: { realTimePricesWithPromotion: true },
        caller: 'GoodsDetail',
        pricesWithPromotionSpecialSceneType: closeEstimatedAndAbPrice ? 1 : 0
      }),
      schttp({
        url: '/api/productInfo/attr/get',
        params: { id: goodsId },
      })
    ])
      
    if (attrRes?.info) {
      // 将静态链路的sku_list中的mall_stock字段赋值到动态链路的sku_list中
      const sku_list = state.productIntroData?.attrSizeList?.sale_attr_list?.[goodsId]?.sku_list || []
      attrRes.info?.sale_attr_list?.[goodsId]?.sku_list?.forEach?.(item => {
        if (item?.stock > 20) {
          item.stock = 20
        }
        const sku = sku_list?.find(_ => _.sku_code == item.sku_code)
        if(sku){
          item.mall_stock = sku?.mall_stock
        }else {
          item?.mall_stock?.forEach?.(mall => {
            if (mall?.stock > 20) {
              mall.stock = 20
            }
          })
        }
      })
      Object.assign(state.productIntroData, {
        attrSizeList: attrRes.info
      })
    }
    if (res[0]?.goods_id) {
      const mall = state.productIntroData.detail.mall
      const data = res[0] || {}
      for (let i = 0; i < data.mall_price?.length; i++) {
        const mall_code = data.mall_price[i].mall_code
        const appPromotion = data.mallAppPromotion.filter(
          (_) => _.mall_code == mall_code
        )
        const promotionInfo = data.mallPromotionInfo.filter(
          (_) => _.mall_code == mall_code
        )
        mall[mall_code] = {
          ...mall[mall_code],
          ...data.mall_price[i],
          appPromotion,
          promotionInfo,
        }
      }
      Object.assign(state.productIntroData.detail, {
        ...state.productIntroData.detail,
        mall,
      })
      state.realtimePriceStatus = true
      state.realtimePricePromotionStatus = true
    }
    dispatch('fetchCouponsPickingRules')
    dispatch('getSkcEstimatedInfo')
  },
  updateRealTimePrice({ state, commit, dispatch, rootState }) {
    const { getPrice = {}, attrSizeList = {} } = rootState.hotModules.productInfo
    const mall = state.productIntroData.detail.mall
    const data = getPrice || {}
    for (let i = 0; i < data.mall_price?.length; i++) {
      const mall_code = data.mall_price[i].mall_code
      const appPromotion = data.mallAppPromotion.filter(
        (_) => _.mall_code == mall_code
      )
      const promotionInfo = data.mallPromotionInfo.filter(
        (_) => _.mall_code == mall_code
      )
      mall[mall_code] = {
        ...mall[mall_code],
        ...data.mall_price[i],
        appPromotion,
        promotionInfo,
      }
    }

    commit('updateAttrList', attrSizeList)
    commit('updateDetailMall', mall)
    dispatch('getSkcEstimatedInfo')
    logForSsrAdvertisementAbPrice({
      goods_id: state.currentGoodsId,
      mall_code: state.productIntroData.mallCode,
      closeEstimatedAndAbPrice: state.productIntroData.closeEstimatedAndAbPrice,
      realTimeGetPrice: getPrice
    })
    commit('updateRealtimePriceStatus', true)
    // commit('updateRealtimePricePromotionStatus', true)
  },

  async fetchCouponsPickingRules ({ state, getters, commit }) {
    const { cat_id, business_model, goods_sn, productRelationID, retailPrice, salePrice  } = state.productIntroData.detail
    if (getters.priceDetect || business_model == 1) return

    // ABT决定发不发请求
    const { DetailShowCouponsPickingRules } = await abtservice.getUserAbtResult({ posKeys: 'DetailShowCouponsPickingRules' })
    if (DetailShowCouponsPickingRules.param !== 'ShowRules') {
      return
    }

    const params = {
      category_id: cat_id || '',
      model: business_model || '',
      product_code: productRelationID || '',
      retail_price: retailPrice?.amount || '',
      sale_price: salePrice?.amount || retailPrice?.amount || '',
      sku_code: goods_sn || ''
    }
    try {
      let result = await schttp({ url: `/api/coupon/getCouponsPickRules/get`, params })
      const showCouponsPickingTips = result.info?.[0]?.have_tag || false
      commit('assignState', { showCouponsPickingTips })
      if (showCouponsPickingTips) {
        daEventCenter.triggerNotice({
          daId: '1-8-6-116'
        })
      }
    } catch (error) {
      console.error(error)
    }
  
  },
  getCCCDescConfig({ state, commit }) {
    schttp({
      url: `/api/productInfo/cccConfig/get`,
      params: {
        cateId: state.productIntroData.detail.cat_id,
        sku: state.productIntroData.detail.goods_sn,
        lang: gbCommonInfo.lang,
        tsp: JSON.stringify(state.productIntroData.tsp || {}),
      },
    }).then((res) => {
      const desc = res.desc?.data?.content?.[0]?.content?.props || {}
      const tips =
        res.tips?.data?.content?.[0]?.content?.props?.items?.[0]
          ?.tipsDocument || ''
      commit('assignState', {
        cccConfig: {
          desc,
          tips,
          isReady: true,
        },
      })
    })
  },
  showTipModal({ commit }, data) {
    commit('showTipModal', data)
  },
  hideTipModal({ commit }) {
    commit('hideTipModal')
  },
  // 设置默认尺码选中
  setDefaultGoodsSize({ commit }) {
    commit('assignState', {
      externalSkuCode: getQueryString({ key: 'skucode' }) || '',
    })
  },
  showLoginModal({ commit }, data) {
    commit('showLoginModal', data)
  },
  hideLoginModal({ commit }) {
    commit('hideLoginModal')
  },
  setBeforeLoginLikeType({ commit }, data) {
    commit('setBeforeLoginLikeType', data)
  },
  loginModalSucCb({ dispatch, state, commit }, status) {
    const { callback } = state.loginModal || {}
    commit('assignState', { noResetUnfold: true })
    // 执行回调
    callback?.(status)
    // 收藏
    if (state.loginModal.type === 'qv-add-wish') {
      state.loginModal.originRef.addToWish()
    }

    if (state.loginModal.type === 'review_feedback') {
      if (!(typeof window !== 'undefined' && window?.gbCommonInfo?.OPEN_DETAIL_LOGINED_RELOAD && window?.gbCommonInfo?.WEB_CLIENT === 'shein')) {
        state.loginModal.originRef.refreshReviewFeedback()
      }
    }

    if (state.loginModal.type === 'detail_coupon') {
      state.loginModal.originRef.isLogin = true
    }

    if (state.loginModal.type === 'reviews_tologin') {
      state.loginModal.originRef?.onAfterLoginToShowMoreComment()
    }

    state.isLoginChange = true

    dispatch('setBeforeLoginLikeType', {
      likeType: state.loginModal.likeType,
    })

    dispatch('hideLoginModal')

    // 购物车模块刷新
    cart_module.reload()

    // 顶部用户模块刷新
    userModule.update_top_login()

    const goods_id = state.productIntroData?.detail?.goods_id
    if (goods_id) dispatch('getProductIntroData', goods_id)
  },
  async getProductBadges({ state, commit, getters }) {
    const { goods_id, cat_id, goods_sn } = state.productIntroData.detail || {}
    const { defaultMallCode, mallCode } = state
    const { PromotionalBelt } = getters.screenAbtConfig

    const fields = {
      beltLabel: !!PromotionalBelt?.param?.ShowPromotionalBelt !== 'NO',
      estimatedPrice: true,
      cccTspBadges: true,
      locateLabels: true,
      maskLayer: true,
    }
    
    const result = await itemSer.getAtomicInfo({
      isPaid: !!getters.isPaidUser, // 是否付费用户
      goods: [{ goods_id, cat_id, goods_sn, mall_code: mallCode || defaultMallCode }],
      pageKey: 'page_goods_detail',
      sceneKey: 'PRODUCT_DETAIL',
      caller: 'GoodsDetail',
      needAssign: false,
      cccParams: {
        cateId: goods_sn,
        cateType: 3,
        realCateId: cat_id
      },
      fields,
    })

    const atomInfo = result?.[goods_id] || {}
    const maskLayer = handledMaskLayer(atomInfo?.detailPcStandardView?.maskLayer || {})

    commit('assignState', {
      badgeInfo: atomInfo?.badges?.BEFORE_NAME?.[0] || null,
      beltInfo: atomInfo?.detailPcStandardView?.beltLabels || {},
      exclusivePromotionInfo: atomInfo?.exclusivePromotionInfo || {},
      locateLabelsInfo: atomInfo?.detailPcStandardView?.locateLabels || {},
      maskLayer,
    })
  },
  getApolloConfig({}, key) {
    return new Promise((resolve) => {
      schttp({
        url: '/api/config/apollo/get',
        method: 'POST',
        data: {
          apolloKeys: key,
        },
      }).then((res) => {
        resolve(res?.info || {})
      })
    })
  },
  async fetchBrandSeriesDetail({ state, dispatch, commit, getters }, { type, only }) {
    let brandDetail = {}, seriesDetail = {}
    if (type == 'brand') { // 只请求品牌
      [brandDetail] = await Promise.all([
        dispatch('fetchBrandDetail')
      ])
    } else if (type == 'series') {
      seriesDetail = await dispatch('fetchSeriesDetail')
    } else {
      [brandDetail, seriesDetail] = await Promise.all([
        dispatch('fetchBrandDetail'),
        dispatch('fetchSeriesDetail'),
      ])
    }
    commit('assignState', { brandDetail, seriesDetail })
    const { storeBusinessType } = state.productIntroData.storeInfo
    /** 品牌集成店 */
    const isBrandStore = storeBusinessType == 2
    /** 新品牌模块 */
    const isShowNewStoreBrand = getters.isBrandHasTargetTag
    
    const data = await formatBrandSeriesDetail({ 
      brandDetail, 
      seriesDetail, 
      isBrandStore: isBrandStore || isShowNewStoreBrand,
      language: state.language 
    })

    commit('assignState', { brandSeriesDetail: data })
    // 不展示店铺信息时，在此将数据状态修改为已准备好
    if (only) {
      // TODO-staging 难以优化的链路，未来尝试用getter中computed属性判断所有分支数据准备情况
      commit('assignState', { storeAndBrandDataReady: true })
    }
  },
  // 查询品牌详细信息以及关联的选品
  async fetchBrandDetail({ state }) {
    const brandCode = state.productIntroData?.getSeriesAndBrand?.brand?.brand_code
    if (!brandCode) return

    const params = {
      brand_code: brandCode,
      need_intro: '1',
    }
    const res = await schttp({
      url: '/api/productInfo/brandDetail/get',
      params
    })

    return res?.info && Object.keys(res.info).length ? res.info : null
  },
  // 查询系列详细信息以及关联的选品
  async fetchSeriesDetail({ state }) {
    const { series, series_list } = state.productIntroData?.getSeriesAndBrand || {}
    let seriesCode = series?.id
    if (series_list?.length) {
      seriesCode = series_list.map(item => item?.id)?.join(',') || seriesCode
    }
    if (!seriesCode) return

    const params = {
      series_ids: seriesCode,
    }
    const res = await schttp({
      url: '/api/productInfo/seriesDetail/get',
      params
    })
    if (res?.info?.length) {
      const findPriorityIdx = (item) => {
        const { series_type, logo_show_position } = item || {}
        if (series_type === 1 && logo_show_position === 1) return 1002
        if (series_type === 1 && logo_show_position === 2) return 1001
        return 0
      }
      const info = res.info.sort((a, b) => findPriorityIdx(b) - findPriorityIdx(a))
      const result = info.find((item) => item?.series_name && item?.image_url && item?.series_description)
      if (result) return result
    }

    return res?.info?.[0] && Object.keys(res.info[0]).length
      ? res.info[0]
      : null
  },
  // 店铺信息处理 =======
  // 获取店铺信息
  async fetchStoreInfo({ state, commit, dispatch, getters }) {
    try {
      const { store_code, business_model, goods_sn } = state.productIntroData.detail
      const { storeInfo, sellerLabelInfo } = state.productIntroData
      gbCommonInfo.robotStoreData = { storeCode: store_code, business_model, pageName: 'productDetailPage', skc: goods_sn }
      if (!validStoreCode(store_code)) return

      const { code, data = {} } = await schttp({
        url: '/api/store/otherInfo/get',
        params: {
          store_code,
          store_type: storeInfo?.storeType,
          labels: 'preferredSeller,Show_Description,BR_infashion_store',
        },
      })

      if (code == -1) return
      const {
        storewishlist,
        BrandCollectionAdjust,
      } = getters.screenAbtConfig
      const { quantity = 0, labelList = [], link = '' } = data || {}
      let totals = quantity
      if (totals) {
        totals = blurStoreItemsNum(totals)
      }
      const  fashionLabel = sellerLabelInfo.filter?.(item => item.labelCategoryCode == 'FS')?.map?.(item => { return { ...item, labelCode: 'BR_infashion_store' } }) || []
      const result = {
        ...storeInfo,
        id: storeInfo.storeCode,
        type: 'store',
        logo: transformImg({ img: storeInfo.logo }),
        originLogo: storeInfo.logo,
        name: storeInfo.title || '',
        desc_bak: state.language.SHEIN_KEY_PC_17600,
        // showPreferredSeller,
        totals,
        labelList: [...labelList, ...fashionLabel],
        link,
        // 版本迭代或地域需要的abt控制样式，将这些控制参数集成于此
        extAbt: {
          BrandCollectionAdjust,
          storewishlist,
        }
      }
      commit('assignState', { localStoreInfo: result })
      // 获取店铺关注信息

      gbCommonInfo.robotStoreData = { ...result, business_model, pageName: 'productDetailPage', skc: goods_sn }
    } catch (e) {
      console.error(e)
    }
    commit('assignState', { updateStoreReady: true })
  },
  // 获取店铺关注信息和用户关注店铺状态，注入state.storeFollowInfo
  // 仅在abt storewishlist 命中时执行
  async fetchStoreFollowInfo({ state, commit }) {
    let { storeAttention, storeAttentionStatus, shopRank = 0 } = state.realtimeBffInfo.storeInfo || {}
    const { storeProductNum = '' } = state.productIntroData.storeInfo || {}
    const storeFollowInfo = {
      show: true,
      followCount: storeAttention,
      salesVolume: storeProductNum,
      userFollowState: Number(storeAttentionStatus),
      // eslint-disable-next-line @shein-aidc/abt/abt
      abtAnaInfo: abtservice.getUserAbtResultForAnalysis({ posKeys: 'storewishlist' }).sa,
    }
    commit('assignState', {
      storeRate: shopRank,
      storeFollowInfo,
      // 将数据状态修改为已准备好
      storeAndBrandDataReady: true,
    })
  },
  // 获取店铺品牌信息 以及 优先级处理
  // * 店铺、品牌信息拉取完毕后需要修改 state.storeAndBrandDataReady 为true
  getAllSignDetail({ state, dispatch }) {
    const { storeStatus, storeBusinessType } = state.productIntroData.storeInfo
    let requestQueue = []
    // 品牌集成店铺优先级最高
    if(storeBusinessType == 2) {
      requestQueue = [
        () => dispatch('fetchStoreInfo'),
        () => dispatch('fetchBrandSeriesDetail', { type: 'brand' })
      ]
    } else if (storeStatus) {
      requestQueue = [
        () => dispatch('fetchBrandSeriesDetail', {}),
        () => dispatch('fetchStoreInfo')
      ]
    } else {
      requestQueue = [
        () => dispatch('fetchBrandSeriesDetail', { only: true })
      ]
    }
    Promise.all(requestQueue.map(_ => _()))
  },
  async updateRealBffInfo({ state, getters, commit, dispatch }){
    try {
      // const abtInfo = await getUserAbtData()
      // const { goodssaleattribute } = abtInfo || {}
      // const showSkcTag = goodssaleattribute?.param?.lowest_skc === 'on'
      // if (!showSkcTag) {
      //   commit('assignState', { realTimeBffReady: true })
      //   commit('assignState', { showColorsTag: true })
      //   return null
      // }
      const { goods_id } = state.productIntroData.detail || {}
      const { mallCode } = getters
      const _useDebug = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo?.NODE_SERVER_ENV !== 'production' : false
      let addressCookie = getLocalStorage('addressCookie') || {}
      let address_cache = getLocalStorage('address_cache') || {}
      let { addressId, countryId, stateId, cityId, districtId } = addressCookie
      let addressState = address_cache[stateId]
      let city = address_cache[cityId]
      let district = address_cache[districtId]
      let shippingParams = {
        countryId,
        addressId,
        state: addressState,
        city,
        district,
        stateId,
        cityId
      }
      const bffInfo = await schttp({
        method: 'GET',
        url: '/product/get_goods_detail_realtime_data',
        params: {
          goods_id,
          mallCode: mallCode,
          // web不需要接口自动切换mall
          isUserSelectedMallCode: 1,
          isQueryIsPaidMember: 1,
          ...shippingParams
        },
        headers: _useDebug ? { 'sheindebug': 676 } : {},
        useBffApi: true,
      })
      if(+bffInfo?.code === 0){
        commit('assignState', { realtimeBffInfo: bffInfo?.info || {} })
      }
    }catch(e) {
      console.error(e)
    }
    commit('assignState', { realTimeBffReady: true })
    commit('assignState', { showColorsTag: true })
    dispatch('fetchStoreFollowInfo')
  },
  async updateFindSimilarFloat({ state, commit, getters }, config = {}) {
    const { from } = config
    const { skuInfo, productIntroData } = state
    const { isCurrentSoldOut } = getters
    const timeStamp = new Date().getTime()
    commit('assignState', { findSimilarApiTimeStamp: timeStamp })
    // 重置状态
    const initFindSimilarFloat = () => {

      commit('SimilarFloat/updateFindSimilarFloatInfo', {
        loading: false,
        show: 0,
        products: [],
        from,
        filterSize: '',
        mode: from === 'pageInit' && !skuInfo?.sku_code ? 'modal' : 'module',
        hasMoreProducts: false,
        showLoginModal: null,
      })

      // commit('assignState', {
      //   findSimilarFloatInfo: {
      //     loading: false,
      //     show: 0,
      //     products: [],
      //     from,
      //     filterSize: '',
      //     mode: from === 'pageInit' && !skuInfo?.sku_code ? 'modal' : 'module',
      //     hasMoreProducts: false,
      //     showLoginModal: null,
      //   }
      // })
    }
    // 当前商品不售
    if (!isCurrentSoldOut) {
      initFindSimilarFloat()
      return
    }

    // 售罄sku切换时展示loading
    commit('SimilarFloat/updateFindSimilarFloatInfo', Object.assign(state.SimilarFloat.findSimilarFloatInfo, { loading: true }))
    const { detail } = productIntroData || {}
    const currentSizeInfo = skuInfo?.sku_sale_attr?.find?.((item) => item?.attr_id == 87) || {}
    const { cat_id, goods_id } = detail
    const abtInfo = await getUserAbtData()
    const { similaritemsnew, soldoutsimilar } = abtInfo || {}
    const soldoutsimilarParam = soldoutsimilar?.param?.soldoutsimilar
    if (!soldoutsimilarParam || soldoutsimilarParam === 'none' || !similaritemsnew?.param) {
      initFindSimilarFloat()
      return
    }
    let rule_id = ''
    const matchResult = similaritemsnew?.param?.match?.(/rule_id=([^&]+)/)
    rule_id = matchResult && matchResult.length >= 2 ? (matchResult[1] || '') : ''
    const filterSize = soldoutsimilarParam === 'show_limit' && currentSizeInfo?.attr_value_name || ''
    const data = await schttp({
      url: '/api/recommend/facadeAtom/get', 
      method: 'POST',
      data: {
        limit: 20,
        // req_num: 20,
        pageEnable: 0,
        scene_id: 18,
        pageNum: 1,
        rule_id,
        contextParams: {
          goods_list: [{
            cate_id: cat_id,
            goods_id: goods_id,
          }],
          filter_size: filterSize
        },
        filterSoldOutConfig: {
          on: true, // *开启售罄过滤
          isPatch: true, // 过滤后补全数据
          ignoreGoodsIds: [goods_id] // 主商品不过滤
        },
        atomFields: {
          realTimeTspLabels: { label_cloud: ['600020741'], machine_label: ['3120'] }, // 全端泛列表场景支持替换营销图主图展示
          detailImage: true, // 全端泛列表场景支持替换营销图主图展示
          estimatedPrice: true
        },
        newPreHandle: true,
      },
    })
    if (state?.findSimilarApiTimeStamp != timeStamp) return
    const { products } = data?.info || {}
    if (!products || products?.length < 10) {
      initFindSimilarFloat()
      return
    }

    commit('SimilarFloat/updateFindSimilarFloatInfo', {
      loading: false,
      show: (state.SimilarFloat.findSimilarFloatInfo?.show || 0) + 1,
      products,
      from,
      filterSize: filterSize,
      mode: from === 'pageInit' && !skuInfo?.sku_code ? 'modal' : 'module',
      hasMoreProducts: products?.length === 20,
      showLoginModal: (data) => {
        commit('showLoginModal', data)
      },
    })
  },
  // 领券失败\登录状态切换更新到手价券列表
  async updateEstimatedCouponList ({ state, getters, commit, dispatch }) {
    let { skuInfo, newFlashPromotion } = state
    let { mallCode, detail } = state.productIntroData
    const { goods_id, goods_sn } = detail || {}
    let { flashType } = getters
    if ([2, 3, 4].includes(flashType) && newFlashPromotion) {
      const { items } = await promotionCenter.assignItems({
        items: [
          {
            ...state.productIntroData.detail.mall[mallCode],
            goods_id,
            goods_sn,
          },
        ],
        scence: 'detail',
        caller: 'GoodsDetail',
        isGetNewFlashPromotion: true,
      })
      commit('assignState', {
        newFlashPromotion: items[0].newFlashPromotion || null,
      })
      return
    }
    if (skuInfo.mall?.[mallCode]) {
      const result = await schttp({
        url: '/api/productInfo/attr/get',
        params: { id: goods_id, _t: Date.now()  }
      })
      if (result.code != 0) return
      let curSkuCode = skuInfo.sku_code
      let originSkuInfo = result.info?.sale_attr_list?.[goods_id]?.sku_list?.find(item => item.sku_code === curSkuCode) || {}
      let newSkuInfo = cloneDeep(skuInfo)
      let newCouponList = (originSkuInfo.mall_price?.find?.(item => item.mall_code === mallCode) || []).coupons
      if (newSkuInfo.mall?.[mallCode]?.coupons) newSkuInfo.mall[mallCode].coupons = newCouponList
      commit('assignState', {
        skuInfo: newSkuInfo
      })
      return 
    }
    return dispatch('getRealtimePrice')
  },

  // 获取店招下面的推荐商品
  async getRecommendProducts({ state, getters, commit }, onlyGetStoreFlashSaleInfo = false){
    const { cat_id, goods_id, store_code, brandInfo, series } = state.productIntroData.detail
    const { storeType, storeStatus } = state.productIntroData.storeInfo
    const showStore = !!([1, 2, 6].includes(+storeType) && storeStatus ) // 1: 平台店铺, 2: 自营店铺 6: 1P
    let config = {}
    if(!showStore){
      config = {
        brand_id: brandInfo?.id, 
        series_group_id: series?.series_group_id, 
        series_id: series?.id
      }
    }else{
      config = { store_code }
      const { isBrandStore, isQualityStore } = getters
      const isInFashionStore = getters.isInFashionStore
      const storeflash = getters.screenAbtConfig?.storeflash?.p
      let storeflashAbt = storeflash?.storeflash !== 'none' && !!storeflash?.storeflash
      if((isBrandStore || isInFashionStore || isQualityStore) && storeflashAbt){
        config.is_flash_sale = 1
        config.is_flash_sale_top = storeflash?.flashfirst === 'first' ? 1 : 0
      }
    }

    const limit = 20 // 统一请求数量，在页面去截取数量
    const { defaultMallCode, mallCode } = state
    const params = {
      rule_id: '', //'rec_ver:S18R3.0',
      cat_id,
      goods_id,
      limit,
      mall_code: mallCode || defaultMallCode,
      ...config
    }
    const { code, data } = await schttp({
      url: '/api/recommend/goodsRecommend/get',   
      params
    })
    const listData = code == 0 ? data : null
    const { store_list, brand_list, series_list, series_group_list, storeFlashSaleInfo } = listData
    if(!onlyGetStoreFlashSaleInfo){
      let listType = ''
      if(store_list?.products?.length){
        listType = 'store_list'
      } else if (brand_list?.products?.length) {
        listType = 'brand_list'
      } else if (series_group_list?.products?.length) {
        listType = 'series_group_list'
      } else if (series_list?.products?.length){
        listType = 'series_list'
      }
      if(listType){
        const { products = [] } = listData[listType]
        commit('assignState', { recProducts: products || [] })
      }
    }
    commit('assignState', { storeFlashSaleInfo })
  },
  // 获取商详底部Customers Also Viewed下面的推荐商品
  async getAlsoViewRecommendlist({ state, commit, getters }, pageNum = 1){
    const { detail, cateNodeId } = state.productIntroData
    const { cat_id, goods_id } = detail
    const abtConfig = getters.screenAbtConfig || {}
    const isYmalRecommendYes = abtConfig?.ymalrecommend?.param?.recommend === 'yes'
    const jsonRuleId = (isYmalRecommendYes ? abtConfig.ProductDetailRecommend?.param : abtConfig.ProductDetailCustomersAlsoViewedList?.param) || '' 
    const data = await schttp({
      url: '/api/recommend/facadeAtom/get',   
      method: 'POST',
      data: {
        atomFields: {
          beltLabel: true,
          cccTspBadges: true,
          estimatedPrice: true,
          locateLabels: true,
          newFlashPromotion: true,
          plusSize: true,
          prices: true,
          promoLabel: true,
          promotion: true,
          quickShip: true,
          sellingPoints: true,
          seriesAndBrand: true,
          sheinClubPromotionInfo: true,
          topPick: true,
          realTimeTspLabels: { label_cloud: ['600020741'], machine_label: ['3120'] }, // 全端泛列表场景支持替换营销图主图展示
          detailImage: true, // 全端泛列表场景支持替换营销图主图展示
          ...(await getDetailRecommendAtomFields() || {}),
        },
        newPreHandle: true,
        ...(isYmalRecommendYes ? { scene_id: 131, req_num: 500 } : { scene_id: 4 }),
        limit: 40,
        pageEnable: 1,
        pageNum,
        jsonRuleId: JSON.stringify(jsonRuleId),
        contextParams: {
          attribute: state.mainAttribute ? state.mainAttribute : state.attribute,
          cate_ids: cat_id,
          goods_ids: goods_id,
          node_id: isYmalRecommendYes ? cateNodeId : '',
          // 131重叠类目id暂不接入，传空值
          overlap_id: isYmalRecommendYes ? '' : undefined,
        },
        filterSoldOutConfig: {
          on: true, // *开启售罄过滤
          isPatch: true, // 过滤后补全数据
          ignoreGoodsIds: [goods_id] // 主商品不过滤
        },
      },
    })
    if(pageNum == 1){
      commit('assignState', { recDrawerProducts: data?.info?.products || [] })
    } else {
      const res = [...state.recDrawerProducts, ...(data?.info?.products || [])]
      commit('assignState', { recDrawerProducts: res })
    }
    return getters.recommendDrawerProducts
  },
  // 获取商详凑单弹窗内的凑单推荐商品
  async getAddMoreRecommendlist({ state, commit, getters }, { firstFetch = false, pageNum = 1 } = {}){
    const { cat_id, goods_id } = state.productIntroData.detail
    if(firstFetch && state.addOnitemTotal > 0 && state.addOnitemGoodsId == goods_id){
      return state.addOnItemProducts
    }
    const { directTag, returnTag } = getters.addOnItemCouponInfo?.recommendInfo || {}
    const addnewhotJsonAbt = getters.screenAbtConfig.addnewhotJson
    let include_tsp_id = directTag || ''
    let exclude_tsp_id = returnTag || ''
    const _reqAbtInfo = await getDetailRecommendCardV3ReqAbtInfoCCCParams({
      newProductCard: getters.newProductCard,
      isPaidUser: getters.isPaidUser
    })
    const data = await schttp({
      url: '/api/recommend/facadeAtom/get',   
      method: 'POST',
      data: {
        req_num: 300,
        limit: 20,
        firstPageLimit: 40,
        pageEnable: 1,
        scene_id: 152,
        pageNum,
        jsonRuleId: JSON.stringify(addnewhotJsonAbt?.param || {}),
        location: 'addnewhotJson',
        ..._reqAbtInfo,
        contextParams: {
          add_on_type: 5,
          include_tsp_id,
          exclude_tsp_id,
          cate_ids: cat_id,
          goods_ids: goods_id,
        },
        atomFields: {
          'promotion': true,
          'plusSize': true,
          'sellingPoints': true,
          'seriesAndBrand': true,
          'cccTspBadges': true,
          'sheinClubPromotionInfo': true,
          'vipDiscountPrices': true,
          'newFlashPromotion': true,
          'promoLabel': true,
          'beltLabel': true,
          'estimatedPrice': false,
          'prices': true,
          'topPick': true,
          'locateLabels': true
        },
        filterSoldOutConfig: {
          on: true, // *开启售罄过滤
        }
      },
    })
    if(data?.info?.products){
      data.info.products = data.info.products.map(item => ({ ...item, isAddOnItem: true })) || []
    }
    if(pageNum == 1){
      commit('assignState', { addOnitemTotal: data?.info?.total || 0 })
      commit('assignState', { addOnItemProducts: data?.info || {} })
    } 
    return data?.info || {}
  },
  // 获取商详凑单弹窗内的ofen bought with商品
  async getOftenBWRecommendlist({ state, commit, getters }, { firstFetch = false, pageNum = 1, onlyForTabs = false, filter_cate_id = '' } = {}){
    const { cat_id, goods_id } = state.productIntroData.detail
    if(firstFetch && !onlyForTabs && state.oftenBWProductsTotal > 0 && state.addOnitemGoodsId == goods_id){
      return state.oftenBWProducts
    }
    if(firstFetch && onlyForTabs && state.oftenBWTabs.length && state.addOnitemGoodsId == goods_id){
      return state.oftenBWTabs
    }
    const jsonRuleIdAbt = onlyForTabs ? getters.screenAbtConfig.addbagpopupcates : getters.screenAbtConfig.addbagpopupgoods
    const _reqAbtInfo = await getDetailRecommendCardV3ReqAbtInfoCCCParams({
      newProductCard: getters.newProductCard,
      isPaidUser: getters.isPaidUser
    })
    const data = await schttp({
      url: '/api/recommend/facadeAtom/get',   
      method: 'POST',
      data: {
        req_num: 500,
        limit: 20,
        firstPageLimit: 40,
        pageEnable: 1,
        scene_id: onlyForTabs ? 256 : 255,
        location: onlyForTabs ? 'addbagpopupcates' : 'addbagpopupgoods',
        pageNum,
        jsonRuleId: JSON.stringify(jsonRuleIdAbt?.param || {}),
        ..._reqAbtInfo,
        contextParams: {
          add_on_type: 5,
          cate_id: cat_id,
          cate_ids: cat_id,
          goods_id: goods_id,
          goods_ids: goods_id,
          filter_cate_id,
        },
        atomFields: {
          'promotion': true,
          'plusSize': true,
          'sellingPoints': true,
          'seriesAndBrand': true,
          'cccTspBadges': true,
          'sheinClubPromotionInfo': true,
          'vipDiscountPrices': true,
          'newFlashPromotion': true,
          'promoLabel': true,
          'beltLabel': true,
          'estimatedPrice': true,
          'prices': true,
          'topPick': true,
          'locateLabels': true
        },
        filterSoldOutConfig: {
          on: true, // *开启售罄过滤
        }
      },
    })
    if(pageNum == 1){
      if(onlyForTabs) {
        commit('assignState', { oftenBWTabs: data?.info?.products || [] })
        commit('assignState', { addOnitemGoodsId: goods_id })
        return state.oftenBWTabs || []
      } else{
        if(filter_cate_id == ''){
          commit('assignState', { oftenBWProductsTotal: data?.info?.total || 0 })
          commit('assignState', { oftenBWProducts: data?.info || {} })
        }
      }
    }
    return data?.info || {}
  },
  async getBffCouponInfo({ commit, rootGetters }){
    const { currency, appLanguage } = window?.gbCommonInfo || {}
    const mainCouponCode = rootGetters['AddBtn/mainCouponCode'] || ''
    const secondaryCoupon = rootGetters['AddBtn/secondaryCoupon'] || []
    const data = {
      couponCode: mainCouponCode || '',
      curCouponCode: mainCouponCode || '',
      activityFrom: 'detail_recpop_coupon_add',
      subCouponCodes: secondaryCoupon?.join(',') || '',
      sort: 1, 
    }
    const res = await schttp({
      method: 'POST',
      url: '/coupon/addItemInfo',
      data,
      headers: {
        'AppCurrency': currency,
        'AppLanguage': appLanguage
      },
      useBffApi: true
    })
    commit('assignState', {
      bffCouponInfo: res
    })
    return res
  },
  async fetchRecDataActions({ commit, dispatch }) {
    let fetchRecDataPromise = null
    fetchRecDataPromise = Promise.all([
      dispatch('getOftenBWRecommendlist', { firstFetch: true }),
      dispatch('getOftenBWRecommendlist', { onlyForTabs: true, firstFetch: true })
    ])
    commit('assignState', {
      fetchRecDataPromise
    })
  },
  // 获取tsp数据
  async updateExposedList({ state, commit }, goods_id) {
    goods_id = Number(goods_id)
    if(state.exposedFilterList.includes(goods_id)) return
    if(state.exposedFilterList.length >= 100){
      state.exposedFilterList.shift()
    }
    commit('assignState', {
      exposedFilterList: [...state.exposedFilterList, goods_id]
    })
  },
  async autoGetCouponAtAddBag(
    { state, dispatch, getters },
    { needToast: needToast = false, needToastFail: needToastFail = false, isMainGoods: isMainGoods = false }
  ) {

    if (!isLogin()) return
    let { getEstimatedInfo, freeShippingInfo } = getters
    let { matchFreeShippingCoupons = [], isFreeShippingCouponForAutoGet } = freeShippingInfo ?? {}
    const { language } = state
    
    const getEstimatedCoupon = () => {
      if (!getEstimatedInfo) return
      if (!getEstimatedInfo?.optimalCouponList?.length) return
      const { EstimatedPrice } = getEstimatedInfo
      if (!(EstimatedPrice?.p?.E_Price_Cal === 'Non_Threshold' || EstimatedPrice?.p?.E_Price_Cal === 'Have_Threshold')) return
      const couponCodes = getEstimatedInfo?.optimalCouponList?.filter(coupon => {
        const isBindCoupon = coupon?.is_bind == 1 // 是绑定券。是否是绑定类型,0-否，1-是
        const notReceived = coupon?.bind_status != 1 // 未领取。 1已领取 2未领取
        return isBindCoupon && notReceived
      })?.map(coupon => coupon?.coupon_code) || []
      return couponCodes
    }

    const getFreeShippingCoupons = () => {
      let result = []
      matchFreeShippingCoupons.forEach(coupon => coupon.coupon_status != 1 && result.push(coupon.coupon))
      return result
    }

    let estimatedCouponCodes = getEstimatedCoupon() || []
    // 主商品时才会自动领取符合条件的免邮券
    let freeShippingCoupons = isFreeShippingCouponForAutoGet && isMainGoods ? getFreeShippingCoupons() : []

    let couponCodes = [...estimatedCouponCodes, ...freeShippingCoupons]
    if (!couponCodes.length) return 

    schttp({
      method: 'POST',
      url: '/api/productInfo/bindCoupon/post',
      data: {
        coupon_codes: couponCodes
      },
    }).then(res => {
      const failAllrange = !res || res.code != 0 || res.info?.failureList?.find?.(item => couponCodes.includes(item.couponCode))
      const successCodesList = res.info?.successList?.map(item => item.couponCode)
      const isEstimatedCouponSuccess = estimatedCouponCodes.length && estimatedCouponCodes.every(item => {
        return successCodesList?.includes(item)
      })
      if (isEstimatedCouponSuccess) {   // 最优券必须全部都领取成功，才会提示领取成功
        needToast && SToast({
          content: language.SHEIN_KEY_PC_26346 || 'We automatically help you to claim coupons',
        })
      }

      const failEstimated = estimatedCouponCodes.length && res.info?.failureList?.find?.(item => estimatedCouponCodes.includes(item.couponCode))
      if(failEstimated && needToast && needToastFail){ // 由促销弹窗点击“领券&加车”按钮过来时，最优券-部分失败/全部失败提示失败
        const errorCode = failEstimated?.code
        failEstimated && SToast({
          content: language[errorCode]
        })
      }
      dispatch('updateEstimatedCouponList')
      dispatch('updateRealBffInfo')
      const abtest = abtUserAnalysis({ posKeys: 'EstimatedPrice' })?.sa
      daEventCenter.triggerNotice({
        daId: '1-8-6-93',
        extraData: {
          coupon_id: couponCodes.join(`,`),
          status: failAllrange ? 0 : 1,
          abtest
        }
      })
    })
  },
  async buyNow({ commit, dispatch, state, getters, rootState }) {
    const saleAttrList = state.saleAttrList
    const { skuInfo } = state || {}
    let { detail } = state.productIntroData || {}
    const hasAttrs = saleAttrList?.sku_list.length
    let sku_code = ''
    if (hasAttrs) {
      sku_code = skuInfo?.sku_code || ''
    } else {
      sku_code = saleAttrList?.skuList?.[0]?.sku_code || ''
    }
    let quickship_tp = 0
    if (!!rootState.quickShip) {
      quickship_tp = getters.screenAbtConfig?.DetailPageQuickShipShow?.param == 'DetailShowQuick'?.param == 'DetailShowQuick' ? 1 : 0
    } else {
      quickship_tp = 3
    }
    if (hasAttrs && !sku_code) {
      commit('AddBtn/updateCheckNotSelectedTips', rootState['AddBtn'].checkNotSelectedTips + 1)
      triggerSubmitBuyNow({
        goods_id: detail?.goods_id,
        mall_code: String(getters.mallCode),
        quickship_tp,
        sku_code: '',
        sku_id: detail?.goods_sn,
        result: 0,
        result_reason: 'Please select size',
        location: 'page',
        is_buynow: 1,
        nobuynow_reason: '',
      })
    } else {
      // 选中sku，走领券，跳转
      const { getEstimatedInfo } = getters || {}
      const currentPrice = getEstimatedInfo?.value?.amount || skuInfo?.mall?.[getters.mallCode]?.salePrice?.amount || state.productIntroData?.detail?.mall?.[getters.mallCode]?.salePrice?.amount || 0
      
      await buyNow({
        language: state.language,
        httpSuccessCallBack: async () => await dispatch('autoGetCouponAtAddBag', { needToast: true, delayToast: 0 }),
        needJump: true,
        mall_code: String(state.productIntroData.mallCode),
        quantity: rootState.quantity,
        sku_code,
        checkout_total: currentPrice,

        daData: {
          goods_id: detail?.goods_id,
          quickship_tp,
          sku_id: detail?.goods_sn,
          location: 'page',
          is_buynow: '1',
          nobuynow_reason: '',
        }
      })
    }
  },
  async asyncHotModles({ commit, state, dispatch }) {
    const { productIntroData } = state || {}
    const { detail, mallCode, tsp } = productIntroData || {}
    const { goods_id } = detail || {}
    // const { DetailShowCouponsPickingRules } = getters.screenAbtConfig
    // const getCouponsPickingTipsStatus = DetailShowCouponsPickingRules?.param == 'ShowRules' // AB时机有问题暂时先不请求
    const getCouponsPickingTipsStatus = false
    const tspKeys = [3213]
    const tspParams = tspKeys.reduce((acc, key) => {
      if (tsp?.[key]) {
        acc[key] = tsp?.[key]
      }
      return acc
    }, {})
    const res = await schttp({
      method: 'POST',
      url: '/api/productInfo/realTimeData/query',
      data: {
        goods_id,
        mallCode,
        tsp: tspParams,
        specialSceneType: state.productIntroData?.closeEstimatedAndAbPrice ? 1 : 0,
        getCouponsPickingTipsStatus,
        transportTypes: state.productIntroData?.shippingDetail?.shippingMethods?.map(item => item.transport_type) || []  
      }
    })
    if (res && res.code === 0) {
      commit('updateHot', res.data)
      dispatch('updateRealTimePrice')
    }

  },
  async getRecommendGroupInfo({ state, getters, commit, rootGetters }) {
    const { isHitComplianceMode, OPEN_BTF } = getters

    // 初始化数据
    commit('Recommend/updateNewGtlInfo', [])

    const { showNewOutfit } = rootGetters['Recommend/newOutfitAbt'] || {}
    const { goods_imgs, detail, isPaidUser, cateNodeId } = state.productIntroData || {}
  
    // const showNewOutfit = true
   

    const requestParams = {} // 请求参数
    const requestFileds = {} // 声明服务端请求链路颗粒
    const { goods_id, cat_id, goods_sn } = detail || {}

    const fileds = []
    // if(needBuyBoxData) { // buybox场景参数组装
    //   fileds.push('buyBox')
    //   requestFileds.buyBox = 1
    //   requestParams.page_num = 1
    // }
    // 请求bff buybox
    const buybox_recommend_info_params = rootGetters['BuyBox/buybox_recommend_info_params']
    getBuyboxRecommendInfo(buybox_recommend_info_params).then((res) => {
      const context = res.info || {}
      commit('updateBuyBoxRecInfoBff', context)
    })

    const goodsImgs = {}
    if (goods_imgs) {
      const { detail_image, main_image } = goods_imgs || {}
      goodsImgs.detail_image = detail_image?.map?.((item) => {
        return {
          thumbnail: item.thumbnail,
        }
      }) || null
      goodsImgs.main_image = main_image?.thumbnail ? { thumbnail: main_image.thumbnail } : null
    }
    fileds.push('newGtlAndOutfitInfo')
    fileds.push('outfitImgUrlFail')
    requestFileds.getTheLook = 1
    requestParams.goods_imgs = goodsImgs

    const result = await schttp({ 
      url: '/api/productInfo/recommendGroup/query',
      method: 'post',
      data: {
        fileds,
        requestFileds,
        requestParams: {
          ...requestParams,
          goods_id,
          cat_id,
          goods_sn,
          node_id: cateNodeId,
          isPaidUser: !!isPaidUser,
        }
      },
    })
    // if (needBuyBoxData) {
    //   const buyBoxRecInfo = result?.info?.buyBox || []
    //   if(buyBoxRecInfo.length) {
    //     await getDetailRecPreTreat({ products: buyBoxRecInfo })
    //   }
    //   commit('updateBuyBoxRecInfo', buyBoxRecInfo, { root: true })
    // }
    const newGtlAndOutfitInfo = result?.info?.newGtlAndOutfitInfo || []
    if (showNewOutfit) { // 新搭配场景结果处理
      const firstRecommend = newGtlAndOutfitInfo?.[0] || {}
      if (!firstRecommend?.related_goods?.length) return
      const products = []
      newGtlAndOutfitInfo?.forEach?.((item) => {
        products.push(...(item?.related_goods || []))
      })
      const params = firstRecommend.related_goods.map((item) => {
        return {
          mall_code: item.mall_code,
          goods_id: item.goods_id,
          skc: item.goods_sn,
        } 
      })
      const [bundlePrice] = await Promise.all([
        schttp({
          url: '/api/productInfo/getBundlePrice/get',
          method: 'POST',
          data: {
            params
          }
        }),
        getDetailRecPreTreat({ products }),
      ])
      const config = {
        isPaidUser,
        isHitComplianceMode,
      }
      firstRecommend.bundlePrice = getCalcBundlePrice(bundlePrice?.info, state.language, config) || {}
      commit('Recommend/updateNewGtlInfo', newGtlAndOutfitInfo)
    }
    // 上报
    if (!OPEN_BTF && newGtlAndOutfitInfo.length > 0) {
      daEventCenter.triggerNotice({
        daId: '2-21-19'
      })
    }
    return result
  },
  // 实时数据返回之后的回调处理
  async realTimeUpdateCallBack({ state, getters, dispatch }) {
    if (getters.OPEN_BTF && state.isUpdateSkc) {
      await dispatch('getBTFData')
      dispatch('BTF/btf_update')
      return
    }
    // 可扩展
    dispatch('getRecommendGroupInfo')
  },
  getSkcEstimatedInfo({ commit, state, getters }) {
    let { screenAbtConfig, mallCode, promotionInfo, flashType, sheinClubPromotionInfo, isAllSoldOut, mallStock, complianceMode, exclusivePromotionPrice, complianceModeDe, isMatchCateId, pspcPriceInfo, s3ClubPriceInfo } = getters
    let { productIntroData, SiteUID, newFlashPromotion } = state
    const { estimatedPriceConfig = [] } = productIntroData?.apolloConfig?.sizeTipsConfig || {}
    let showNoSatisfied = true
    if (!isMatchCateId) showNoSatisfied = false
    if (screenAbtConfig?.collestrategy?.p?.colldisplayrest === 'popup') showNoSatisfied = false
    let skcEstimatedInfo = getEstimatedInfo({
      abtValue: {
        EstimatedPrice: screenAbtConfig?.EstimatedPrice,
        EstimatedShowType: screenAbtConfig?.EstimatedShowType?.p?.EstimatedShowType,
        EstimatedNothreShowType: screenAbtConfig?.EstimatedNothreShowType?.p?.EstimatedNothreShowType,
        Estimatedclubshow: 'New',
        Estimatedstrategy: screenAbtConfig?.EstimatedNothreShowType?.p?.Estimatedstrategy,
        sheinappwordsize: screenAbtConfig?.sheinappwordsize?.p?.sheinappwordsize,
        middleast: screenAbtConfig?.middleast?.p?.middleast,
        showaddprice: screenAbtConfig?.EstimatedNothreShowType?.p?.showaddprice,
        shownprice: screenAbtConfig?.estimatednpcsprice?.p?.shownprice,
        showncate: screenAbtConfig?.estimatednpcsprice?.p?.showncate,
      },
      mallCode: mallCode,
      detail: productIntroData.detail,
      skuInfo: null,
      isPaidUser: getters.isPaidUser,
      showSheinClub: getters.showSheinClub,
      exclusivePromotionPrice: exclusivePromotionPrice,
      s3ClubPriceInfo,
      flashType: flashType,
      newFlashPromotion,
      promotionInfo,
      sheinClubPromotionInfo,
      estimatedPriceConfig,
      SiteUID,
      isSoldOut: isAllSoldOut || mallStock == 0,
      showNoSatisfied,
      closeEstimatedAndAbPrice: productIntroData.closeEstimatedAndAbPrice,
      complianceMode,
      complianceModeDe,
      pspcPriceInfo,
      parentCats: state.productIntroData?.parentCats,
      N_ESTIMATED_SITE_CAT: productIntroData?.apolloConfig?.DETAIL_SECOND_CONFIG?.N_ESTIMATED_SITE_CAT || {},
      tspLabelsTags: productIntroData?.tspLabels?.tag || '',
      brandBarginsPromotionInfo: getters.brandBarginsPromotionInfo,
      detailStreamPartThird: state.DETAIL_STREAM_PART.THIRD,
    })
    commit('updateSkcEstimatedInfo', skcEstimatedInfo)
  },
  /**
   * 获取商详 BTF 数据
   */
  async getBTFData({ state, getters }) {
    const { goods_sn, goods_id, cat_id, productRelationID } = state.productIntroData.detail || {}

    const { 
      screenAbtTspConfig, 
      mallCode, 
      isSoldOut, 
      isPaidUser,
      mallStock,
      isAllSoldOut,
    } = getters

    // 参数xxxx
    const is_sold_out = isAllSoldOut || mallStock == 0

    // 组合购
    const comboBuyParamsInfo = {
      is_need_combo_buy: screenAbtTspConfig?.buytogethernew?.param?.buytogethernew === 'show',
      // is_need_combo_buy: true,
      comboBuyParams: {
        cat_id,
        goods_id,
        goods_sn,
        mall_code: mallCode,
        isEnterDetailCall: '1',
        is_sold_out: isSoldOut,
        is_paid_user: isPaidUser,
      }
    }

    await prefetchBTF({
      goods_sn,
      goods_id,
      cat_id,
      is_sold_out,
      mall_code: mallCode,
      is_paid_user: isPaidUser,
      product_relation_id: productRelationID,
      comboBuyParamsInfo
    })
  },
  /**
   * CDN - SSR 后首屏第一阶段执行的任务
   * 1. 更新价格信息
   * 2. 更新 SKU 信息库存信息
   * 3. 更新 加车按钮信息
   * @param {*} 
   */
  initDetailSreamPart({ commit, dispatch, getters, state }, data) {
    commit('updateHot', data) // 更新实时数据
    dispatch('updateRealTimePrice')
    // dispatch('initSkuInfo')

    // 商详 BTF 数据预取
    if (getters.OPEN_BTF) {
      dispatch('getBTFData')
    }

    setTimeout(() => {
      commit('updateDetailStreamPart', { key: 'FIRST', value: true })

      let skucode = getQueryString({ key: 'skucode' })

      skucode = state.productIntroData?.relatedLocalInfo?.localSkuMap?.[skucode] || skucode
      skucode && commit('updateExternalSkuCode', skucode)
    }, 0)

    setTimeout(() => {
      dispatch('prefetchDisplayInfo')
      dispatch('prefetchShippingList')
    }, 10)
  },
  
  // 第二阶段完成
  secondConmplete({ commit, dispatch }) {


    setTimeout(() => {
      commit('updateDetailStreamPart', { key: 'THIRD', value: true })
      commit('updateSkcStock')
    }, 0)
  
    setTimeout(() => {
      // dispatch('setDefaultGoodsSize')
      commit('updateDetailStreamPart', { key: 'FOURTH', value: true })
      dispatch('getSkcEstimatedInfo')
    }, 100)
  },
  // 橱窗数据预请求
  prefetchDisplayInfo({ dispatch, getters }) {
    const { screenAbtConfig, forbidAtmosphereFlow } = getters
    const { hotnews = {} } = screenAbtConfig || {}
    const { whereshow } = hotnews?.param || {}
    const addBtnShow =
      (whereshow == 'andaddtobag' || whereshow == 'onlyaddtobag') &&
      !forbidAtmosphereFlow
    if (addBtnShow)  {
      preFetchQueues.addBtnDisplayInfo = dispatch('getDisplayInfoFunc', 1)
    } else {
      preFetchQueues.galleryDisplayInfo = dispatch('getDisplayInfoFunc', 0)
    }
  },


  // 获取橱窗数据
  asyncGetDisplayInfo({}, showAddBagBehaviorLabel) {
    if (showAddBagBehaviorLabel) {
      return preFetchQueues.addBtnDisplayInfo
    }
    return preFetchQueues.galleryDisplayInfo
  },

  clearDiaplayMemory(showAddBagBehaviorLabel) {
    if (showAddBagBehaviorLabel) {
      preFetchQueues.addBtnDisplayInfo = null
    }
    preFetchQueues.galleryDisplayInfo = null
  },

  getDisplayInfoFunc({ state }, showAddBagBehaviorLabel) {
    const { goods_id, goods_sn, cat_id, store_code, stock } = state.productIntroData.detail || {}
    return schttp({
      url: `/api/productInfo/displayWindowInfo/query`,
      method: 'POST',
      data: { 
        goods_id, 
        goods_sn, 
        cat_id, 
        sceneType: 'page_goods_detail', 
        showBehaviorLabel: 1, 
        showAddBagBehaviorLabel,
        store_code, 
        stock
      }
    })
  },

  // 预取物流数据
  async prefetchShippingList ({ state, getters, dispatch, commit }) {
    const cache = await dispatch('getCacheParams')
    const { storeInfo, detail } = state.productIntroData
    const { business_model, goods_sn } = detail
    const { goodsFreeShippingInfo, mallCode } = getters
    const { isDebug } = window.gbCommonInfo || {}
    let params = {}
    if (cache?.countryId) {
      // "{\"74\":\"France\",\"127075\":\"Aisne\",\"127590\":\"Achery\"}"
      const ADDRESS_CACHE = JSON.parse(localStorage.getItem('address_cache') ||  '{}')
      params = {
        country_id: cache?.countryId,
        state: ADDRESS_CACHE?.[cache?.stateId] || '',
        city: ADDRESS_CACHE?.[cache?.cityId] || '',
        stateId: cache?.stateId || '',
        cityId: cache?.cityId || '',
        countryId: cache?.countryId || '',
        country: ADDRESS_CACHE?.[cache?.countryId] || '',
        district: ADDRESS_CACHE?.[cache?.districtId] || '',
      }
    } else {
      const countryData = await UserInfoManager.get({ key: 'Country', actionType: 'getCountryCode' })
      const defaultCountryId = isDebug ? '226' : ''
      params = {
        countryId: state.hotModules?.shipping?.countryId || countryData?.countryId || defaultCountryId,
        country_id: state.hotModules?.shipping?.countryId || countryData?.countryId || defaultCountryId
      }
    }

    const queryParams = {
      productFreeId: goodsFreeShippingInfo?.rule_id,
      joinTransportTypes: goodsFreeShippingInfo?.join_transport_types,
      business_model,
      market_scope: storeInfo?.salesArea,
      goods_sn: state.productIntroData?.relatedLocalInfo?.skc_name || goods_sn,
      mall_code: mallCode || '',
      free_policy_limit: 0,
      free_activity_limit: 0,
      freight_activity_limit: 0,
      storeCode: storeInfo?.storeCode,
      ...params,
    }
    const res = await schttp({
      url: '/api/productInfo/shippingDetail/get',
      params: queryParams,
    })
    commit('updateShippingDetail', res)

    const transportTypes = res?.shippingMethods?.map(item => item.transport_type) || []
    const transportConfig = {
      isHl: false,
      isQuickShip: false, // 普通
      mallCode: mallCode,
      storeCode: storeInfo?.storeCode,
    }
    const transport_list = []
    transportTypes.forEach(transportType => {
      transport_list.push({
        ...transportConfig,
        transportType,
      })
    })
    transport_list.push({
      ...transportConfig,
      isQuickShip: true
    })
    transport_list.push({
      ...transportConfig,
      isHl: true
    })
    const agingParams = {
      addressInfoOther: {
        ...params,
        siteUid: window.gbCommonInfo?.SiteUID,
      },
      scene: 'productDetail',
      type: 2,
      mall_list: [{
        mall_code: mallCode,
        store_code: storeInfo?.storeCode,
        skc_code: state.productIntroData?.relatedLocalInfo?.skc_name || goods_sn,
        cart_id: state.productIntroData?.relatedLocalInfo?.goods_id || state.productIntroData?.detail?.goods_id,
      }],
      day_time_params: {
        transport_list: transport_list,
        goods_sn_list: [{ goodsSn: state.productIntroData?.relatedLocalInfo?.skc_name || goods_sn, mallCode }]
      },
    }
    const agingInfo = await schttp({
      url: '/api/common/getAgingInfo/get',
      method: 'POST',
      data: agingParams,
    })
    const transportList = agingInfo?.info?.transportList || []
    // const transportList = [
    //   {
    //     "transportType": "postage",
    //     "isQuickShip": false,
    //     "isHl": false,
    //     "tip": "Promise to arrive before Valentine's Day."
    //   },
    //   {
    //     "transportType": "standard_express",
    //     "isQuickShip": false,
    //     "isHl": false,
    //     "tip": "Promise to arrive before Valentine's Day."
    //   },
    //   {
    //     "transportType": "urgent_express",
    //     "isQuickShip": false,
    //     "isHl": false,
    //     "tip": "Promise to arrive before Valentine's Day."
    //   },
    //   {
    //     "transportType": null,
    //     "isQuickShip": true,
    //     "isHl": false,
    //     "tip": "Promise to arrive before Valentine's Day."
    //   },
    //   {
    //     "transportType": null,
    //     "isQuickShip": false,
    //     "isHl": true,
    //     "tip": "Promise to arrive before Valentine's Day."
    //   }
    // ]
    const normalAgingInfo = transportList.filter(item => !item.isHl && !item.isQuickShip)
    const qsAgingInfo = transportList.filter(item => item.isQuickShip)
    const hlAgingInfo = transportList.filter(item => item.isHl)
    const holidayTip = transportList.find(item => item.tip)?.tip || ''

    commit('updateHolidayAgingInfo', {
      normalAgingInfo,
      qsAgingInfo,
      hlAgingInfo,
      holidayTip: holidayTip.replace(/^\.+|\.+$/g, '')
    })
  },

  async getCacheParams({ dispatch }) {
    const addressCookie = await dispatch('getLocalStorageAndRemoveCookie', 'addressCookie')
    let [cache, params] = [null, {}]
    if (addressCookie) {
      // 先读缓存
      cache = typeof addressCookie == 'string' ? JSON.parse(addressCookie) : addressCookie
      if (!isValidCache(cache)) cache = null
    }
    if (cache) {
      params = {
        countryId: cache.countryId,
        stateId: cache.stateId,
        cityId: cache.cityId,
        districtId: cache.districtId,
      }
    }
    return cache ? params : null
  },
  getLocalStorageAndRemoveCookie ({}, key) {
    let cache = ''
    try {
      cache = getLocalStorage(key)
    } catch (error) {
      cache = ''
    }
    return cache
  },
  updateCountryHandler({ state, commit }, country) {
    state.DETAIL_STREAM_PART.THIRD && commit('updateCountry', country)
  },
  setDetailCdn({ state, getters }) {
    const { apolloConfigs } = state.hotModules?.pageInfo || {}
    const { DETAIL_SSR_CDN_VERSION, DETAIL_SSR_CDN_SITE_STATUS, DETAIL_SSR_NO_CDN_SITE } = apolloConfigs || {}
    const { CccGoodsdetail, showsoldoutskc, goodsPicAb, PerformanceLogNodeTestFetch } = getters.screenAbtConfig || {}
    const ABT_MAP = {
      CccGoodsdetail, 
      showsoldoutskc,
      goodsPicAb,
      PerformanceLogNodeTestFetch
    }
    setDetailCacheCookie({
      DETAIL_SSR_CDN_VERSION, 
      DETAIL_SSR_CDN_SITE_STATUS, 
      DETAIL_SSR_NO_CDN_SITE
    },
    ABT_MAP)
  },
  // 构造商详榜单页链接
  async getRankingLink({ state, getters }) {
    if (typeof window === 'undefined') return

    const goods_id = state.productIntroData?.detail?.goods_id || ''
    if (!goods_id) return
    const { rankingListInfo } = getters
    const {
      contentCarrierId,
      carrierSubType,
      cateIds,
      title,
      _src_identifier_list,
    } = rankingListInfo || {}

    const src_identifier = _src_identifier_list.length ? _src_identifier_list.join('`') : '-'

    return getRankingLink({
      title,
      contentCarrierId,
      goods_id,
      carrierSubType,
      cateIds,
      src_identifier,
      tab_page_id: typeof window !== 'undefined' && window.getSaPageInfo?.tab_page_id || '',
    })
  },
}

export default actions
