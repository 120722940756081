import Size from './Size'
import Color from './Color'
import AddBtn from './AddBtn'
import SimilarFloat from './SimilarFloat'
import Recommend from './Recommend'
import BuyBox from './buy-box'
export default {
  Size,
  Color,
  AddBtn,
  SimilarFloat,
  Recommend,
  BuyBox,
}
